import React from "react";
import Ongoing from "../StudentContainer/Livestream/Ongoing";

const StudentLiveStream = () => {
  return (
    <div>
      <Ongoing />
    </div>
  );
};

export default StudentLiveStream;
