import React from "react";
import Livestream from "../StudentContainer/Livestream/Livestream";

const LiveStreamPage = () => {
  return (
    <div>
      <Livestream />
    </div>
  );
};

export default LiveStreamPage;
