import React from "react";
import Schedule from "../StudentContainer/Schedule/Schedule";

const SchedulePage = () => {
  return (
    <div>
      <Schedule />
    </div>
  );
};

export default SchedulePage;
