import React from "react";

import Dashboard from "../StudentContainer/DashBoard/Dashboard";

const DashboardPage = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
