import React from "react";
import LessonContainer from "../../TutorContainer/CoursesContainer/LessonContainer/LessonContainer";

const TLesson = () => {
  return (
    <div>
      <LessonContainer />
    </div>
  );
};

export default TLesson;
