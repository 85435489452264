import React from "react";
import Assignments from "../StudentContainer/Assignments/Assignments";
const AssignmentsPage = () => {
  return (
    <div>
      <Assignments />
    </div>
  );
};

export default AssignmentsPage;
