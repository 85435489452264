import React from "react";
import Challenges from "../StudentContainer/Challenges/Challenges";

const ChallengesPage = () => {
  return (
    <div>
      <Challenges />
    </div>
  );
};

export default ChallengesPage;
