import React from "react";
import "./challenges.css";
export default function ChallengeInfo() {
  return (
    <div className="challenge-info">
      <h3 className="challenge-name">Automata Theory</h3>
      <p className="challenge-desc">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor totam
        quis nesciunt corrupti nulla beatae obcaecati culpa similique, molestias
        autem earum odit! Eius maxime optio expedita perferendis minima
        consequatur sequi.
      </p>
    </div>
  );
}
