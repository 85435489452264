import React from "react";
import LivestreamPrep from "../../TutorContainer/LiveStreamContainer/LivestreamPrep";

const TMeetingPrep = () => {
  return (
    <div>
      <LivestreamPrep />
    </div>
  );
};

export default TMeetingPrep;
