import React from "react";
import PageNotFound from "../StudentContainer/NotFound/PageNotFound";

const NotFoundPage = () => {
  return (
    <div>
      <PageNotFound />
    </div>
  );
};

export default NotFoundPage;
