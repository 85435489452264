import React from "react";
import Schedule from "../../TutorContainer/ScheduleContainer/Schedule";

const TSchedulePage = () => {
  return (
    <div>
      <Schedule />
    </div>
  );
};

export default TSchedulePage;
