import React from "react";
import CourseContainer from "../../TutorContainer/CoursesContainer/CourseContainer";

const TCourses = () => {
  return (
    <div>
      <CourseContainer />
    </div>
  );
};

export default TCourses;
